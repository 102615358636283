import { useEffect } from "react";

import Sitemap from "../pages/Sitemap";
import NotFound from "../pages/NotFound";
import BlogRoutes from "./BlogRoutes";
import Layout from "../components/Common/Layout";
import LandingPage from "../pages/LandingPage";
import ProductsPage from "../pages/ProductsPage";
import SolutionPage from "../pages/SolutionPage";
import AboutUsPage from "../pages/AboutUsPage";
import BookDemoPage from "../pages/BookDemoPage";

import TrialRequestPage from "../pages/TrialRequestPage";
import { Route, Routes, useLocation } from "react-router-dom";
import VideoRoutes from "./VideoRoutes";

export default function RootRoutes() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    if (window.screen.width <= 850 && ["interview", "auth", "dashboard"].some(path => window.location.pathname.includes(path))) {
        return (
            <div className="h-screen bg-primary flex flex-col items-center justify-center text-center px-2 text-base font-normal gap-4">
                <p className="text-white">This website is best viewed on a desktop.</p>
                <p className="text-white">Please switch to a larger device for the best experience.</p>
            </div>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<LandingPage />} />
                <Route path="products" element={<ProductsPage />} />
                <Route path="solution" element={<SolutionPage />} />
                <Route path="about-us" element={<AboutUsPage />} />
                <Route path="demo" element={<BookDemoPage />} />
                <Route path="blogs/*" element={<BlogRoutes />} />
                <Route path="videos/*" element={<VideoRoutes />} />
                <Route path="try-for-free" element={<TrialRequestPage />} />
                <Route path="*" element={<LandingPage />} />
            </Route>

            <Route path="notfound" element={<NotFound />} />
            <Route path="sitemap.xml" element={<Sitemap />} />
        </Routes>
    );
}
