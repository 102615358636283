import React, { useState } from "react";
import ReactPlayer from "react-player/lazy";
import { InlineWidget } from "react-calendly";
import { useNavigate } from "react-router-dom";
import { FaPlay, FaPause } from "react-icons/fa";
import { AnimatedDiv } from "../components/Common/AnimatedDiv";

export default function BookDemoPage() {
    const navigate = useNavigate();
    const [isPlaying, setIsPlaying] = useState(true); // State to control video playback

    const togglePlayback = () => {
        setIsPlaying((prev) => !prev);
    };

    return (
        <div className="w-full flex justify-center flex-col py-5 gap-10">
            <AnimatedDiv className="font-semibold text-2xl md:text-3xl flex-col gap-2 text-darkGray md:leading-snug tracking-wide text-center">
                <h1>Athmick Platform Navigation Demo</h1>
            </AnimatedDiv>
            <div className="flex items-center flex-col gap-5">
                <div className="relative flex justify-center items-center">
                    <ReactPlayer
                        playing={isPlaying}
                        controls={false}
                        pip={false}
                        url="https://skillassessment.blob.core.windows.net/files/athmick-platform-demo-video.mp4"
                        width="95vw"
                    />
                    {/* Play/Pause Button centered on the video */}
                    <div className="absolute flex justify-center items-center top-0 left-0 right-0 bottom-0">
                        <div
                            className="h-16 w-16 flex justify-center items-center bg-white text-primary rounded-full cursor-pointer opacity-80 hover:opacity-100"
                            onClick={togglePlayback}>
                            {isPlaying ? (
                                <FaPause size={32} />
                            ) : (
                                <FaPlay size={32} />
                            )}
                        </div>
                    </div>
                </div>
 
                <InlineWidget
                    styles={{ width: "100%", height: "90vh" }}
                    url="https://calendly.com/roopa-raj-athmick/30min"
                />
            </div>
        </div>
    );
}
