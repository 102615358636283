import axiosInstance from ".";

function submitSessionFeedback(data){
    return axiosInstance.post('/submitSessionFeedback', data)
}

function submitTrialRequest(data){
    return axiosInstance.post('/trials', data)
}

function submitEnquiryRequest(data){
    return axiosInstance.post('/enquiries', data)
}

const CommonService = {
    submitEnquiryRequest,
    submitTrialRequest,
    submitSessionFeedback,
}

export default CommonService;