import { HiMenu } from 'react-icons/hi';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoArrowUpRight } from "react-icons/go";
import logo from './../../assets/icons/new_logo.svg';
import AnimatedButton from '../Common/AnimatedButton';
import { trackEvent } from '../../utils/googleAnalytics';

const Header = () => {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    if(isMenuOpen)
      setIsMenuOpen(false);
    navigate(path);
  }
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onBookADemo = () => {
    trackEvent({
      value: 1,
      action: "Click",
      transport: "beacon",
      nonInteraction: false,
      label: "Book a Demo Button",
      category: "Lead Generation",
    })
    navigateTo('/demo')
  }


  // if(window.location.pathname.includes('/demo')){
  //   return (
  //     <header className="bg-white text-brownGray font-normal p-4 sticky top-0 z-50 drop-shadow-md">
  //       <div className="container mx-auto flex justify-between items-center">
  //         <img 
  //             alt='logo' 
  //             src={logo}
  //             onClick={navigateTo.bind(this, '/')}
  //             className='h-10 object-center cursor-pointer'
  //         />
  //       </div>
  //     </header>
  //   )
  // }
  
  return (
    <header className="bg-white text-brownGray font-normal p-4 sticky top-0 z-50 drop-shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <img 
            alt='logo' 
            src={logo}
            onClick={navigateTo.bind(this, '/')}
            className='h-10 object-center cursor-pointer'
        />
        <ul className="hidden lg:flex space-x-8 text-sm">
          {/* <div className="relative group">
            <button className="flex items-center">
              Product <FaChevronDown className="ml-1" />
            </button>
          </div> */}
          <li className={`${window.location.pathname === '/' ? 'text-blue font-bold': 'text-brownGray font-medium'} cursor-pointer hover:text-blue`} onClick={navigateTo.bind(this, '/')}>Home</li>
          <li className={`${window.location.pathname === '/products' ? 'text-blue font-bold': 'text-brownGray font-medium'} cursor-pointer hover:text-blue`} onClick={navigateTo.bind(this, '/products')}>Product</li>
          <li className={`${window.location.pathname === '/solution' ? 'text-blue font-bold': 'text-brownGray font-medium'} cursor-pointer hover:text-blue`} onClick={navigateTo.bind(this, '/solution')}>Solution</li>
          <li className={`${window.location.pathname === '/about-us' ? 'text-blue font-bold': 'text-brownGray font-medium'} cursor-pointer hover:text-blue`} onClick={navigateTo.bind(this, '/about-us')}>Our Company</li>
          <li className={`${window.location.pathname.includes('/blogs') ? 'text-blue font-bold': 'text-brownGray font-medium'} cursor-pointer hover:text-blue`} onClick={navigateTo.bind(this, '/blogs')}>Blogs</li>
          <li className={`${window.location.pathname.includes('/videos') ? 'text-blue font-bold': 'text-brownGray font-medium'} cursor-pointer hover:text-blue`} onClick={navigateTo.bind(this, '/videos')}>Videos</li>
        </ul>
        <div className="hidden lg:flex space-x-4 items-center">
          <a 
              href={`${process.env.REACT_APP_DASHBOARD_URL}`}
              className="hover:text-blue text-darkGray" 
              target="_blank" 
              rel="noopener noreferrer"
            >
            Customer Login
          </a>
         <div className={`${window.location.pathname !== '/demo' ? 'visible': 'hidden'}`}>
           <AnimatedButton
            onClick={onBookADemo} 
            className="bg-black text-white px-4 py-3 rounded-full text-sm hover:bg-blue hover:text-white">
            <div className='flex gap-2 items-center'>
                <span className='font-medium'>Book A Demo</span>
              <GoArrowUpRight color='white' size={18}/>
            </div>
          </AnimatedButton>
         </div>
        </div>
        <button className="lg:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <HiMenu className="text-2xl" />
        </button>
      </div>
      {isMenuOpen && (
        <div className="lg:hidden mt-4">
          <ul className="flex flex-col space-y-4">
            <li className={`${window.location.pathname === '/' ? 'text-blue font-bold': 'text-brownGray font-medium'} cursor-pointer hover:text-blue`} onClick={navigateTo.bind(this, '/')}>Home</li>
            <li className={`${window.location.pathname === '/products' ? 'text-blue font-bold': 'text-brownGray font-medium'} cursor-pointer hover:text-blue`} onClick={navigateTo.bind(this, '/products')}>Product</li>
            <li className={`${window.location.pathname === '/solution' ? 'text-blue font-bold': 'text-brownGray font-medium'} cursor-pointer hover:text-blue`} onClick={navigateTo.bind(this, '/solution')}>Solution</li>
            <li className={`${window.location.pathname === '/about-us' ? 'text-blue font-bold': 'text-brownGray font-medium'} cursor-pointer hover:text-blue`} onClick={navigateTo.bind(this, '/about-us')}>Our Company</li>
            <li className={`${window.location.pathname.includes('/blogs') ? 'text-blue font-bold': 'text-brownGray font-medium'} cursor-pointer hover:text-blue`} onClick={navigateTo.bind(this, '/blogs')}>Blogs</li>
            {/* <a 
              href="https://dashboard.athmick.ai" 
              className="hover:text-blue text-darkGray" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Customer Login
            </a> */}
            {window.location.pathname !== '/demo' && (
              <AnimatedButton
                onClick={onBookADemo} 
                className="bg-black text-white px-4 py-3 rounded-full text-sm w-44">
                <div className='flex gap-2 items-center'>
                    <span className='font-medium'>Book A Demo</span>
                  <GoArrowUpRight color='white' size={18}/>
                </div>
              </AnimatedButton>
            )}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;