import axiosInstance from ".";

function getBlogs(page=1, limit=10){
    return axiosInstance.get(`/blogs?page=${page}&limit=${limit}`)
}

function getBlog(blogId){
    return axiosInstance.get(`/blogs/${blogId}`)
}


const BlogService = {
    getBlog,
    getBlogs,
}

export default BlogService;