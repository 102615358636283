import './index.css';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@smastrom/react-rating/style.css';
import reportWebVitals from './reportWebVitals';
import { initGA } from './utils/googleAnalytics';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { ToastProvider } from './contextProviders/ToastContext';
import { HelmetProvider } from 'react-helmet-async';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
initGA({trackingId: TRACKING_ID});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ToastProvider>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ToastProvider>
);

reportWebVitals();
