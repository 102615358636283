import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import LoadingComponent from "../Common/LoadingComponent";
import { Helmet } from "react-helmet-async";

// Hard-coded video data
const videos = [
    {
        id: 1,
        title: "Value Proposition/ Athmick",
        description: `Revolutionizing Hiring with AI – Athmick.ai
        
        In today’s competitive job market, HR professionals spend countless hours screening resumes and conducting interviews. Athmick.ai transforms this process with AI-powered interviewing, making hiring faster, smarter, and more efficient.
        
        🔹 Automated Resume Screening – No more manually reviewing endless applications. Our AI identifies the best candidates in seconds.
        🔹 Time-Efficient Interviews – AI-driven interviews streamline the process, reducing hiring time while ensuring consistency and accuracy.
        🔹 Smarter Hiring Decisions – Gain AI-generated insights to make informed choices and hire the right talent effortlessly.
        
        With Athmick.ai, HR teams can focus on strategic decision-making while AI handles the heavy lifting. Experience the future of hiring today.`,
          
        thumbnailUrl: "https://via.placeholder.com/300",
        videoUrl: "https://skillassessment.blob.core.windows.net/files/Generated Video final 1.mp4", // Example video URL
        createdAt: "2025-01-01"
    },
    {
        id: 2,
        title: "Recruiter Scenaio",
        description: `The traditional hiring process can be exhausting—sifting through endless resumes, conducting repetitive interviews, and struggling with inefficiencies. In this video, an HR consultant frustrated with outdated methods discovers Athmick.ai, the AI-driven solution revolutionizing recruitment.
🔹 Video Profiling – Gain deeper insights into candidates beyond their resumes.
🔹 AI-Powered Initial Interviews – Automate the first round of interviews and shortlist top talent effortlessly.
🔹 Technical Interviews with Coding Challenges – Assess candidates' real-world problem-solving skills through AI-driven coding tests and evaluations.
🔹 Seamless, Data-Driven Hiring – Reduce hiring time, improve accuracy, and enhance decision-making with AI-powered insights.
With Athmick.ai, HR teams can streamline hiring, focus on top talent, and build stronger teams faster than ever.`,
        thumbnailUrl: "https://via.placeholder.com/300",
        videoUrl: "https://skillassessment.blob.core.windows.net/files/Final Happy Mickey (2).mp4", // Example video URL
        createdAt: "2025-01-10"
    },
    {
        id: 3,
        title: "One-minute walkthrough the Platform",
        description: `Hiring the right talent can be a complex and time-consuming process, from crafting job descriptions to shortlisting resumes and conducting technical screenings. Athmick.ai simplifies and automates every stage of recruitment, making hiring faster, smarter, and more efficient.
🔹 AI-Assisted Job Description Creation – Generate clear, optimized JDs tailored to your needs.
🔹 Automated Resume Shortlisting – Identify top candidates in seconds with AI-powered filtering.
🔹 Technical Screening & Coding Challenges – Assess candidates' skills with AI-driven evaluations.
🔹 Seamless Interview Process – From initial screening to final selection, streamline hiring with AI.
Experience the future of recruitment with Athmick.ai—where efficiency meets intelligence.`,
          
        thumbnailUrl: "https://via.placeholder.com/300",
        videoUrl: "https://skillassessment.blob.core.windows.net/files/Demo Video Final.mp4", // Example video URL
        createdAt: "2025-02-05"
    }
];
export default function VideoPage() {
    const { id } = useParams();
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(false);
 
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    useEffect(() => {
        // Simulate fetching video data
        setLoading(true);
        const selectedVideo = videos.find(video => video.id === parseInt(id));
        if (selectedVideo) {
            setVideo(selectedVideo);
        }
        setLoading(false);
    }, [id]);

    if (loading) {
        return (
            <div className='w-full md:max-w-[75%] mx-auto py-10 flex flex-col md:flex-row justify-center md:px-0 px-5 gap-5'>
                <div className="flex-1 flex flex-col gap-5 justify-center items-center">
                    <LoadingComponent />
                </div>
            </div>
        );
    }

    if (!video) {
        return (
            <div className='w-full md:max-w-[75%] mx-auto py-10 flex flex-col md:flex-row justify-center md:px-0 px-5 gap-5'>
                <div className="flex-1 flex flex-col gap-5 justify-center items-center">
                    <span>Video not found</span>
                </div>
            </div>
        );
    }

    return (
        <div className='w-full md:max-w-[75%] mx-auto py-5 flex-col flex justify-center gap-5'>
            <Helmet>
                <title>{video?.title}</title>
                <meta name="description" content={video?.description} />
            </Helmet>
            <div className="flex items-center gap-1 text-primary cursor-pointer">
                <MdKeyboardArrowLeft onClick={goBack} size={26} />
                <h1 className='text-xl text-primary font-bold'>Videos</h1>
            </div>
            <div className="flex-1 flex flex-col justify-center pt-5 px-4 md:px-0">
                <div className="flex flex-col gap-2">
                    <h2 className="font-semibold text-darkGray text-3xl">{video?.title}</h2>
                    <p className="font-normal mt-5 whitespace-pre-line">{video?.description}</p>
                    <span className="text-xs text-brownGray font-normal">{moment(video?.createdAt).fromNow()}</span>
                    <div className="my-10 w-3/4 m-auto">
                        <video
                            controls
                            className="w-full rounded-lg shadow-lg"
                            src={video?.videoUrl}
                            alt={video?.title}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}