import moment from "moment";
import { useState } from "react";
import { DataView } from 'primereact/dataview';
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../Common/LoadingComponent";
import v1 from "../../assets/video_thumb/1.png"
import v2 from "../../assets/video_thumb/2.png"
import v3 from "../../assets/video_thumb/3.png"

export default function VideosPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // Hard-coded video data
    const videos = [
        {
            id: 1,
            title: "Value Proposition/ Athmick",
            description: `Revolutionizing Hiring with AI – Athmick.ai
            
            In today’s competitive job market, HR professionals spend countless hours screening resumes and conducting interviews. Athmick.ai transforms this process with AI-powered interviewing, making hiring faster, smarter, and more efficient.
            
            🔹 Automated Resume Screening – No more manually reviewing endless applications. Our AI identifies the best candidates in seconds.
            🔹 Time-Efficient Interviews – AI-driven interviews streamline the process, reducing hiring time while ensuring consistency and accuracy.
            🔹 Smarter Hiring Decisions – Gain AI-generated insights to make informed choices and hire the right talent effortlessly.
            
            With Athmick.ai, HR teams can focus on strategic decision-making while AI handles the heavy lifting. Experience the future of hiring today.`,
              
            thumbnailUrl: v1,
            videoUrl: "https://skillassessment.blob.core.windows.net/files/Generated Video final 1.mp4", // Example video URL
            createdAt: "2025-01-01"
        },
        {
            id: 2,
            title: "Recruiter Scenaio",
            description: `The traditional hiring process can be exhausting—sifting through endless resumes, conducting repetitive interviews, and struggling with inefficiencies. In this video, an HR consultant frustrated with outdated methods discovers Athmick.ai, the AI-driven solution revolutionizing recruitment.
🔹 Video Profiling – Gain deeper insights into candidates beyond their resumes.
🔹 AI-Powered Initial Interviews – Automate the first round of interviews and shortlist top talent effortlessly.
🔹 Technical Interviews with Coding Challenges – Assess candidates' real-world problem-solving skills through AI-driven coding tests and evaluations.
🔹 Seamless, Data-Driven Hiring – Reduce hiring time, improve accuracy, and enhance decision-making with AI-powered insights.
With Athmick.ai, HR teams can streamline hiring, focus on top talent, and build stronger teams faster than ever.`,
            thumbnailUrl: v2, // Updated URL
            videoUrl: "https://skillassessment.blob.core.windows.net/files/Final Happy Mickey (2).mp4",
            createdAt: "2025-01-10"
        },
        {
            id: 3,
            title: "One-minute walkthrough the Platform",
            description: `Hiring the right talent can be a complex and time-consuming process, from crafting job descriptions to shortlisting resumes and conducting technical screenings. Athmick.ai simplifies and automates every stage of recruitment, making hiring faster, smarter, and more efficient.
🔹 AI-Assisted Job Description Creation – Generate clear, optimized JDs tailored to your needs.
🔹 Automated Resume Shortlisting – Identify top candidates in seconds with AI-powered filtering.
🔹 Technical Screening & Coding Challenges – Assess candidates' skills with AI-driven evaluations.
🔹 Seamless Interview Process – From initial screening to final selection, streamline hiring with AI.
Experience the future of recruitment with Athmick.ai—where efficiency meets intelligence.`,
            thumbnailUrl: v3, // Updated URL
            videoUrl: "https://skillassessment.blob.core.windows.net/files/Demo Video Final.mp4",
            createdAt: "2025-02-05"
        }
    ];

    const navigateTo = (path) => {
        navigate(path);
    }

    const itemTemplate = (item, index) => {
        return (
            <div 
                key={item.id}
                className="w-full md:w-[40%] xl:w-[28%] shadow-lg hover:shadow-md hover:bg-white rounded-md col-12 sm:col-6 lg:col-12 xl:col-4">
                <div className="surface-border surface-card border-round flex justify-between flex-col">
                    <img 
                        alt="Video Thumbnail"
                        src={item?.thumbnailUrl}
                        className="cursor-pointer w-full h-60"
                        onClick={() => navigate(`/videos/${item?.id}`)}                 />
                    <div className="flex flex-col p-4 gap-4 h-full">
                        <h1 className="text-sm line-clamp-2">{item?.title}</h1>
                        <p className="text-base text-darkGray font-semibold line-clamp-2">{item?.description}</p>
                        <span className="text-xs font-semibold mt-auto">{moment(item?.createdAt).format('DD-MMM-YYYY')}</span>  
                    </div>
                </div>
            </div>
        );
    };

    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;

        let list = items.map((item, index) => {
            return itemTemplate(item, index);
        });

        return <div className="flex gap-10 flex-wrap">{list}</div>;
    };

    if (loading) {
        return (
            <div className='w-full md:max-w-[75%] mx-auto py-5 flex flex-col md:flex-row justify-center md:px-0 px-5 gap-5'>
                <div className="w-full py-10">
                    <LoadingComponent />
                </div>
            </div>
        );
    }

    if (!loading && videos?.length === 0) {
        return (
            <div className='w-full md:max-w-[75%] mx-auto py-5 flex flex-col md:flex-row justify-center md:px-0 px-5 gap-5'>
                <div className="w-full py-10 flex justify-center items-center">
                    <span>No videos available</span>
                </div>
            </div>
        );
    }

    return (
        <div className='w-full md:max-w-[75%] mx-auto py-5 flex flex-col md:flex-row justify-center md:px-0 px-5 gap-5'>
            <div className="w-full py-10">
                <DataView 
                    value={videos}  
                    rows={10} 
                    layout="grid"
                    emptyMessage="No videos available"
                    listTemplate={listTemplate}
                />
            </div>
        </div>
    );
}