import './App.css';
import RootRoutes from './routes/RootRoutes';
import { BrowserRouter} from "react-router-dom";

function App() {

  return (
    <BrowserRouter>
        <RootRoutes />
    </BrowserRouter>
  );
}

export default App;
