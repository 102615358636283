import { Route, Routes } from "react-router-dom";
import VideosPage from "../components/Videos/VideosPage";
import VideoPage from "../components/Videos/VideoPage";

export default function VideoRoutes(){

    return (
        <Routes>
            <Route path="" element={<VideosPage />}/>
            <Route path="/:id" element={<VideoPage />} />
        </Routes>
    )
}